import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [8],
		"/error": [33],
		"/explore": [~34],
		"/explore/[species]/[valueChain]/[geography]": [~35,[],[7]],
		"/home": [~36],
		"/profile": [37],
		"/read": [38],
		"/read/templates/[templateId]/latest": [~40],
		"/read/[articleId]": [~39],
		"/tables": [41],
		"/unauthorized": [42],
		"/[context]": [9,[2]],
		"/[context]/analysis": [~11,[2,3]],
		"/[context]/analysis/pelagics/analysisupdate/[...articleId]": [~13,[2,3]],
		"/[context]/analysis/preview/[articleId]": [14,[2,3]],
		"/[context]/analysis/salmonids/analysisupdate/[...articleId]": [~15,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate": [16,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate/marketupdates/[...articleId]": [~17,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate/productionupdates/[...articleId]": [~18,[2,3,4]],
		"/[context]/analysis/salmonids/weeklyupdate/[...articleId]": [~19,[2,3,4]],
		"/[context]/analysis/shrimp/analysisupdate/[...articleId]": [~20,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate": [21,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/marketupdates/[...articleId]": [~22,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/productionupdates/[...articleId]": [~23,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/tradeupdates/[...articleId]": [~24,[2,3,5]],
		"/[context]/analysis/shrimp/weeklyupdate/[...articleId]": [~25,[2,3,5]],
		"/[context]/analysis/templates/[templateId]/latest": [~26,[2,3]],
		"/[context]/analysis/[group]/[category]/[...articleId]": [~12,[2,3]],
		"/[context]/feedback": [~27,[2]],
		"/[context]/resources/abbreviations": [~28,[2,6]],
		"/[context]/resources/definitions": [~29,[2,6]],
		"/[context]/resources/product-categories": [~30,[2,6]],
		"/[context]/resources/weight-conversions": [~31,[2,6]],
		"/[context]/upgrade": [~32,[2]],
		"/[context]/[menu]/[species]/[valueChain]/[geography]": [10,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';